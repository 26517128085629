import request from './request'

function list(data) {
    return request('get','/qxapi/edu/teachingplan/teacher_page', data)
}

function listPlan(data) {
    return request('get','/qxapi/edu/teachingplan/teacher_page_forplan', data)
}


function get(data) {
    return request('get','/qxapi/edu/teachingplan/get', data)
}

function add(data) {
    return request('post','/qxapi/edu/teachingplan/add', data)
}

function upd(data) {
    return request('post','/qxapi/edu/teachingplan/upd', data)
}

function getLessonPlan(data) {
    return request('get','/qxapi/edu/lessonplan/lessonplanbyclass', data)
}

function submitLessonPlan(data) {
    return request('post','/qxapi/edu/lessonplan/submit', data)
}

function getCourseByClassId(data) {
    return request("get", "/qxapi/edu/course/coursesbyclassid", data)
}

export default {
    list,
	get,
	add,
	upd,
    getLessonPlan,
    submitLessonPlan,
    listPlan,
    getCourseByClassId
}
