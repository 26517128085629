<template>
  <div class="edu-frame">
    <edu-nav-bar title="编辑任课计划" :show="show" :hideBack="$route.query.hideBack==1" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
    <!--内容-->
    <div class="content-frame">
      <div class="edu-group" style="margin: 20px auto;">
        <!-- <div class="step-tips">填写报名资料</div>
        <div class="step-tips-detail" style="color: black;">请填写您的基本资料（以下带<span style="color: red;">*</span>的是<span style="color: red;">必填项</span>）</div> -->
        <div class="class-form">
          <!-- <van-field v-if="formData.planType != '4'" readonly v-model="formData.teacherName" required label="教师姓名" placeholder="请输入教师姓名" clearable/> -->
          <van-field v-if="formData.planType != '4'" readonly v-model="formData.className" required label="班级名称" placeholder="请输入班级名称" clearable/>
          <van-field v-if="formData.planType != '4'" readonly v-model="formData.forder" required label="序号" placeholder="请输入序号" clearable/>
          <van-field v-if="formData.planType != '4'" readonly v-model="formData.classTime" required label="上课时间" placeholder="请输入上课时间" clearable/>
          <van-field v-if="formData.planType != '4'" readonly v-model="formData.classRoomName" required label="上课地点" placeholder="请输入上课地点" clearable/>
          <van-field v-if="formData.planType != '4'" readonly v-model="formData.realAmount_Internet" required label="本学期在读人数" placeholder="本学期在读人数" clearable/>
          <van-field v-if="formData.planType != '4'" readonly :value="formData.schoolLengthStr" required label="学制" placeholder="请输入学制" clearable/>
          <van-field v-if="formData.planType != '4'" readonly v-model="formData.classFee" required label="学费定价" placeholder="请输入学费定价" clearable/>
          <van-field readonly required clickable v-model="formData.planTypeStr" label="课程安排" placeholder="请选择课程安排" @click="formData.status == null || formData.status < 2? showPlanType = true : showPlanType = false" /><!-- @click="showPlanType = true" -->
          <div style="color: gray;font-size: 14px;margin-left: 90px;color: red;">
            学制未满的班级，默认班级续开，原则上中途不得升级或结束。
          </div>
          <van-field v-if="formData.planType == '2'" v-model="formData.extra" required label="升级后的班级名称" placeholder="请输入升级后的班级名称" clearable :readonly="formData.status == null || formData.status < 2 ? false : true"/>

          <div v-if="formData.planType != '4' && formData.planType != '3'">
            <van-field v-if="course.depName.indexOf('书画系') > -1 && course.courseName.indexOf('书法') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习何种作品的笔法、结构和章法（如临摹《峰山碑》等）。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('书画系') > -1 && course.courseName.indexOf('绘画') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习绘画的技法、内容（如几何静物、人物风景等）。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('计算机摄影系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习何种摄影技巧或电脑操作方法，摄影课程需注明适用的相机类型（单反/卡片/手机/通用等）后期处理等课程还需注明学习使用的是何种软件（如PS、LR等）。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('外语系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习方向（音标/词汇/句型/口语等）, 如教师指定教材的，需注明教材名称及第几册（如学习《英语入门》第一册）。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('声乐系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的唱法（美声/民俗等）及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('钢琴系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的指法/技巧、音阶和弦及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册（如学习小汤1等）。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('器乐系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的指法/技巧、音阶和弦及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册（如学习小汤1等）。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('舞蹈系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的舞蹈种类及曲目等。" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else-if="course.depName.indexOf('舞蹈系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="旗袍模特类课程如有身高或上课着装要求，请注明。" show-word-limit  :readonly="formData.status == null || formData.status < 2 ? false : true"/>
            <van-field v-else required v-model="formData.intro" rows="2" autosize :label="formData.planType == 3 ? '结课原因' : '课程简介'" type="textarea" maxlength="60" :placeholder="formData.planType == 3 ? '请输入结课原因' : '请输入课程简介'" show-word-limit :readonly="formData.status == null || formData.status < 2 ? false : true"/>
          </div>

          <!-- <van-field v-if="formData.planType == '3'" required v-model="formData.intro" rows="2" autosize :label="formData.planType == 3 ? '结课原因' : '课程简介'" type="textarea" :placeholder="formData.planType == 3 ? '请输入结课原因' : '请输入课程简介'" show-word-limit :readonly="formData.planId==null || formData.planId == '' ? false : true"/> -->


          <van-field v-if="formData.planType == '4'" v-model="formData.teacherName" required label="教师姓名" placeholder="请输入教师姓名" clearable/>
          <van-field v-if="formData.planType == '4'" v-model="formData.className" required label="班级名称" placeholder="请输入班级名称" clearable/>
          <van-field v-if="formData.planType == '4'" v-model="formData.classTime" readonly required label="上课时间" placeholder="请输入上课时间" clearable/>
          <van-field v-if="formData.planType == '4'" v-model="formData.classRoomName" readonly required label="上课地点" placeholder="请输入上课地点" clearable/>
          <van-field v-if="formData.planType == '4'" v-model="formData.schoolLength" readonly required clickable label="学制" placeholder="请选择学制" @click="showSchoolLength = true"/>
          <van-field v-if="formData.planType == '4'" readonly v-model="formData.termQty" required label="学期" placeholder="请输入学期" clearable/>
          <van-field v-if="formData.planType == '4'" v-model="formData.classFee" required label="学费定价" placeholder="请输入学费定价" clearable/>



          <div v-if="formData.planType == '4'">
            <van-field v-if="course.depName.indexOf('书画系') > -1 && course.courseName.indexOf('书法') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习何种作品的笔法、结构和章法（如临摹《峰山碑》等）。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('书画系') > -1 && course.courseName.indexOf('绘画') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习绘画的技法、内容（如几何静物、人物风景等）。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('计算机摄影系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习何种摄影技巧或电脑操作方法，摄影课程需注明适用的相机类型（单反/卡片/手机/通用等）后期处理等课程还需注明学习使用的是何种软件（如PS、LR等）。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('外语系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习方向（音标/词汇/句型/口语等）, 如教师指定教材的，需注明教材名称及第几册（如学习《英语入门》第一册）。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('声乐系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的唱法（美声/民俗等）及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('钢琴系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的指法/技巧、音阶和弦及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册（如学习小汤1等）。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('器乐系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的指法/技巧、音阶和弦及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册（如学习小汤1等）。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('舞蹈系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="注明学习的舞蹈种类及曲目等。" show-word-limit/>
            <van-field v-else-if="course.depName.indexOf('舞蹈系') > -1" required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="旗袍模特类课程如有身高或上课着装要求，请注明。" show-word-limit/>
            <van-field v-else required v-model="formData.intro" rows="2" autosize label="课程简介" type="textarea" maxlength="60" placeholder="请填写课程简介" show-word-limit/>
          </div>


          <div v-if="formData.planType != '3'" style="color: gray;font-size: 14px;margin-left: 90px;color: red;">
            <!--<span v-if="course.depName.indexOf('书画系') > -1 && course.depName.indexOf('书法') > -1">注明学习何种作品的笔法、结构和章法（如临摹《峰山碑》等）。</span>
            <span v-if="course.depName.indexOf('书画系') > -1 && course.depName.indexOf('绘画') > -1">注明学习绘画的技法、内容（如几何静物、人物风景等）。</span>
            <span v-if="course.depName.indexOf('计算机摄影系') > -1">注明学习何种摄影技巧或电脑操作方法，摄影课程需注明适用的相机类型（单反/卡片/手机/通用等）后期处理等课程还需注明学习使用的是何种软件（如PS、LR等）。</span>
            <span v-if="course.depName.indexOf('外语系') > -1">注明学习方向（音标/词汇/句型/口语等）, 如教师指定教材的，需注明教材名称及第几册（如学习《英语入门》第一册）。</span>
            <span v-if="course.depName.indexOf('声乐系') > -1">注明学习的唱法（美声/民俗等）及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册。</span>
            <span v-if="course.depName.indexOf('钢琴系') > -1">注明学习的指法/技巧、音阶和弦及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册（如学习小汤1等）。</span>
            <span v-if="course.depName.indexOf('器乐系') > -1">注明学习的指法/技巧、音阶和弦及曲目等，如教师指定教材的，需注明教材种类（自编/其他教材）、名称及第几册（如学习小汤1等）。</span>
            <span v-if="course.depName.indexOf('舞蹈系') > -1">注明学习的舞蹈种类及曲目等。</span>
            <span v-if="course.depName.indexOf('综合系') > -1">旗袍模特类课程如有身高或上课着装要求，请注明。</span>-->
            <span>（限制字数20-60字以内）书画系、钢琴系、器乐系班级需注明教材名称。</span>

            <!-- 注意事项：钢琴系、书画系班级需注明教材名称。 -->
          </div>

          <van-field v-if="formData.planType != '3'" readonly required clickable v-model="formData.skillRequire" label="专业基础要求" placeholder="请选择专业基础要求" @click="formData.status == null || formData.status < 2 ? showSkillRequire = true : showSkillRequire = false"/> <!-- @click="showSkillRequire = true" -->
          <van-field v-if="formData.planType != '3'" readonly required clickable v-model="formData.sexRequire" label="性别要求" placeholder="请选择性别要求" @click="formData.status == null || formData.status < 2 ? showSexRequire = true : showSexRequire = false"/> <!-- @click="showSexRequire = true" -->
          <van-field v-if="formData.planType != '3'" clickable v-model="formData.ageRequire" label="年龄要求" placeholder="请填写年龄要求" :readonly="formData.status == null || formData.status < 2 ? false : true"/>
          <van-field v-if="formData.planType != '3'" readonly required clickable v-model="formData.teachLanguage" label="授课语言" placeholder="请选择授课语言" @click="formData.status == null || formData.status < 2 ? showTeachLanguage = true : showTeachLanguage = false"/> <!-- @click="showTeachLanguage = true" -->
          <van-field v-if="formData.planType != '3'" v-model="formData.otherRequire" label="其他要求" placeholder="请填写其他要求" :readonly="formData.status == null || formData.status < 2 ? false : true"/>


        </div>
      </div>
    </div>
    <!--内容-->

    <!-- 保存按钮 -->
    <div class="bottom-frame">
      <van-button v-if="formData.status == null || formData.status < 2" :loading="submitting" square block type="primary" @click="submit(1)">保存不提交</van-button>
      <van-button v-if="formData.status == null || formData.status < 2" :loading="submitting" square block type="primary" @click="submit(2)">保存并提交</van-button>
      <van-button v-else :loading="submitting" square block type="primary" @click="$router.back()">返回</van-button>
    </div>


    <!--选择课程安排-->
    <van-popup v-model="showPlanType" position="bottom">
      <van-picker show-toolbar :columns="planTypeOpts" @confirm="selectPlanType" @cancel="showPlanType=false;"/>
    </van-popup>
    <!--选择课程安排-->

    <!--选择学制-->
    <van-popup v-model="showSchoolLength" position="bottom">
      <van-picker show-toolbar :columns="schoolLengthOpts" @confirm="selectSchoolLength" @cancel="showSchoolLength=false;"/>
    </van-popup>
    <!--选择学制-->

    <!--选择专业基础要求-->
    <van-popup v-model="showSkillRequire" position="bottom">
      <van-picker show-toolbar :columns="skillRequireOpts" @confirm="selectSkillRequire" @cancel="showSkillRequire=false;"/>
    </van-popup>
    <!--选择专业基础要求-->

    <!--选择性别要求-->
    <van-popup v-model="showSexRequire" position="bottom">
      <van-picker show-toolbar :columns="sexRequireOpts" @confirm="selectSexRequire" @cancel="showSexRequire=false;"/>
    </van-popup>
    <!--选择性别要求-->


    <!--选择其他要求-->
    <van-popup v-model="showTeachLanguage" position="bottom">
      <van-picker show-toolbar :columns="teachRequireOpts" @confirm="selectTeachLanguage" @cancel="showTeachLanguage=false;"/>
    </van-popup>
    <!--选择其他要求-->

  </div>
</template>
<script>
import {Button, Field, Popup, Picker} from "vant";
import EduNavBar from "@/components/EduNavBar";
import Tools from "@/api/Tools";
import EduConstants from "@/api/EduConstants";
import TeachingPlanApi from "@/api/TeachingPlanApi";
import BaomingApi from "@/api/BaomingApi";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanField: Field,
    VanPopup: Popup,
    VanPicker: Picker
  },
  data() {
    return {
      cust: Tools.getCurCust(),
      LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
      show: false,
      submitting: false,
      showPlanType: false,
      formData: { termQty: 1 },
      planTypeOpts: [{ text: '学制未满，班级续开', value: '1' }, { text: '学制已满，班级升级', value: '2' }, { text: '学制已满，班级结束', value: '3' }, { text: '学制已满，变新开班', value: '4' }],
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      tempIntro: null,
      showSchoolLength: false,
      schoolLengthOpts: [{ text: '半年', value: '半年' }, { text: '一年', value: '一年' }, { text: '两年', value: '两年' }],
      showSkillRequire: false,
      skillRequireOpts: [{ text: '无需基础', value: '无需基础' }, { text: '须有1年基础', value: '须有1年基础' }, { text: '须有2年基础', value: '须有2年基础' }, { text: '须有3年基础', value: '须有3年基础' }],
      showSexRequire: false,
      sexRequireOpts: [{ text: '无限制', value: '无限制' }, { text: '仅限女性报读', value: '仅限女性报读' }],
      showTeachLanguage: false,
      teachRequireOpts: [{ text: '普通话授课', value: '普通话授课' }, { text: '粤语授课', value: '粤语授课' }, { text: '英语授课', value: '英语授课' }, { text: '其他语言或方言', value: '其他语言或方言' }],
      course: { depName: '', courseName: '' }
    }
  },
  methods: {
    submit(status) {
      if (this.formData.planType == 4 && (this.formData.teacherName == null || this.formData.teacherName == '')) {
        this.$dialog.alert({message: "请输入教师名称。"});
        return
      }
      if (this.formData.className == null || this.formData.className == '') {
        this.$dialog.alert({message: "请输入班级名称。"});
        return
      }
      if (this.formData.classTime == null || this.formData.classTime == '') {
        this.$dialog.alert({message: "请输入上课时间。"});
        return
      }
      if (this.formData.classRoomName == null || this.formData.classRoomName == '') {
        this.$dialog.alert({message: "请输入上课地点。"});
        return
      }
      if (this.formData.planType == 4 && (this.formData.schoolLength == null || this.formData.schoolLength == '')) {
        this.$dialog.alert({message: "请输入学制。"});
        return
      }
      if (this.formData.planType == null || this.formData.planType == '') {
        this.$dialog.alert({message: "请选择课程安排。"});
        return
      }
      if (this.formData.planType != 3 && (this.formData.intro == null || this.formData.intro == '')) {
        this.$dialog.alert({message: "请输入课程简介。"});
        return
      }
      if (this.formData.planType != 3 && (this.formData.intro.length < 20 || this.formData.intro.length > 60)) {
        this.$dialog.alert({message: "课程简介必须输入20-60字。"});
        return
      }
      if (this.formData.planType != 3) {
        if (this.formData.skillRequire == null || this.formData.skillRequire == '') {
          this.$dialog.alert({message: "请输入专业基础要求。"});
          return
        }
        if (this.formData.sexRequire == null || this.formData.sexRequire == '') {
          this.$dialog.alert({message: "请输入性别要求。"});
          return
        }
        if (this.formData.teachLanguage == null || this.formData.teachLanguage == '') {
          this.$dialog.alert({message: "请输入授课语言。"});
          return
        }

      }

      // console.log(this.formData)
      if (this.submitting) {
        return
      }
      this.submitting = true
      this.formData.status = status
      TeachingPlanApi.submitLessonPlan(this.formData).then(response => {
        // console.log(response)
        this.submitting = false
        // console.log(response)
        if (response.code==100) {
          this.getLessonPlan()
          this.$toast({message: response.msg});
        }
      }).catch(() => { this.submitting = false })
    },
    selectTeachLanguage(e) {
      this.formData.teachLanguage = e.text
      this.showTeachLanguage = false
    },
    selectSexRequire(e) {
      this.formData.sexRequire = e.text
      this.showSexRequire = false
    },
    selectSkillRequire(e) {
      this.formData.skillRequire = e.text
      this.showSkillRequire = false
    },
    selectSchoolLength(e) {
      this.formData.schoolLength = e.text
      this.showSchoolLength = false
    },
    selectPlanType(e) {
      // 选择政治面貌
      this.formData.planTypeStr = e.text
      this.formData.planType = e.value
      this.showPlanType = false
      // console.log(this.formData.planType)
      if (this.formData.planType == 4) {
        // console.log(1)
        var classId = this.$route.query.classId
        var tempFormData = JSON.parse(JSON.stringify(this.formData))
        this.formData = { planTypeStr: e.text, planType: e.value, classId: classId, teacherId: this.cust.custId, teacherName:this.cust.fullName, termQty: 1, classTime: tempFormData.classTime, classRoomName: tempFormData.classRoomName }
        // console.log('this.formData', this.formData)
      } else if (this.formData.planType == 3) {
        // console.log(2)
        this.getLessonPlan(this.formData.planType)
      } else {
        // console.log(3)
        // console.log('classId')
        this.tempIntro = this.formData.intro
        this.getLessonPlan(this.formData.planType)
      }
    },
    getLessonPlan(planType) {
      var classId = this.$route.query.classId
      var custId = this.cust.custId
      TeachingPlanApi.getLessonPlan({ classId: classId, teacherId: custId }).then(response => {
        // console.log(response)
        this.formData = response.res
        if (this.formData.fee == null) {
          this.getClassById()
        }
        if (planType != null && planType != undefined) {
          this.formData.planType = planType
          if (planType != 3) {
            this.formData.intro = this.tempIntro
          } else {
            this.formData.intro = ''
          }
        }
        if (this.formData.planType != null && this.formData.planType != '') {
          this.formData.planTypeStr = this.planTypeOpts[this.formData.planType - 1].text
        }


      })
    },
    getCourseByClassId() {
      var classId = this.$route.query.classId
      TeachingPlanApi.getCourseByClassId({ classId: classId }).then(response => {
        console.log('getCourseByClassId', response.res)
        this.course = response.res
      })
    },
    getClassById() {
      var classId = this.$route.query.classId
      BaomingApi.getClassById(classId).then(response => {
        this.$set(this.formData, 'classFee', response.res.fee)
      })
    }
  },
  mounted() {
    this.getCourseByClassId()
    this.getLessonPlan()
  }
}
</script>
<style scoped></style>
